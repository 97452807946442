<template>
  <div class="contact">
    <img class="top" src="../../assets/images/app/contact/top.png" alt="" />
    <div class="title">海螺姑娘®教育</div>
    <div class="info">
      <p>
        海螺姑娘是海螺帮主集团旗下的教育品牌，围绕女性8大生命周期开展生活技能类培训，秉承帮助每一位母婴护理者的理念，育人，树德。
      </p>
      <p>
        海螺姑娘是多个新媒体平台的母婴教育市场鳌头，其打造的“海螺姑娘”线上知识付费平台孵化近百万女性护理、育婴育儿讲师，用知识分享创造社会价值，实现人生的二次起飞。
      </p>
      <div class="img-con">
        <img src="../../assets/images/app/contact/1.png" alt="" />
        <img src="../../assets/images/app/contact/2.png" alt="" />
      </div>
      <p>
        “中承·海螺姑娘女子健康培训中心”是由中国中医科学院名医传承计划青岛基地（青岛中承医院）和海螺姑娘共同打造，“中承-海螺姑娘女子健康培训中心”将为母婴护理从业人员提供母婴保育员、催乳师、育婴员职业培训，并开设产后康复、小儿推拿、刮痧、拔罐、中医健康养生知识等特色课程作为母婴职业人群的拓展提升课程。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.contact {
  width: 100%;
  background: #fff;
  padding-bottom: 50px;
  .top {
    width: 750px;
    height: 339px;
  }
  .title {
    background: #e67871;
    height: 80px;
    font-size: 28px;
    padding: 0 30px;
    text-align: center;
    line-height: 80px;
    color: #fff;
  }
  .info {
    font-size: 28px;
    line-height: 60px;
    padding: 0 30px;
    p {
      text-indent: 40px;
      margin: 15px 0 30px 0;
    }
    .img-con {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      img {
        width: 330px;
        height: 220px;
      }
    }
  }
}
</style>
